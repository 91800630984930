<template>
	<el-dialog :visible.sync="show" width="80%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row>
			<el-col :span="6">
				<div ></div>
                <el-image  style="width: 330px;height: 330px;transform: translateX(20px);" v-if="sysUserVo.headUrl" :src="$fileUrl + sysUserVo.headUrl"/>
				<div style="width: 90%;margin: 0 auto;">
					<el-row style="padding-top: 30px;">
						<el-col :span="6">
							<div class="field-title">驾驶员姓名:</div>
						</el-col>
						<el-col :span="18">
							<div>{{sysUserVo.realName ? sysUserVo.realName : '--'}}</div>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="6">
							<div class="field-title">联系方式:</div>
						</el-col>
						<el-col :span="18">
							<div>{{sysUserVo.phone ? sysUserVo.phone : '--'}}</div>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="6">
							<div class="field-title">所属企业:</div>
						</el-col>
						<el-col :span="18">
							<div>{{sysUserVo.companyName ? sysUserVo.companyName : '--'}}</div>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="6">
							<div class="field-title">车牌号:</div>
						</el-col>
						<el-col :span="18">
							<div>{{list.carNum ? list.carNum : '--'}}</div>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="6">
							<div class="field-title">车辆自编号:</div>
						</el-col>
						<el-col :span="18">
							<div>{{list.carNo ? list.carNo : '--'}}</div>
						</el-col>
					</el-row>
				</div>
			</el-col>
			<el-col :span="18" style="border-left: 1px solid #00A0E9;">
				<div style="width: 97%;margin: 0 auto;">
					<el-row :gutter="22" style="padding-top: 10px;font-weight: bold;">
						<el-col>
							<div style="height: 20px;line-height: 20px;border-left: 3px solid #0c8b43;padding-left: 6px;color:#409EFF">报警信息</div>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="6">
									<div class="field-title">报警事件:</div>
								</el-col>
								<el-col :span="18">
									<div>{{list.alarmEvent ? list.alarmEvent : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="6">
									<div  class="field-title">报警级别:</div>
								</el-col>
								<el-col :span="18">
									<div>{{list.levelDesc ? list.levelDesc : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="6">
									<div  class="field-title">报警时间:</div>
								</el-col>
								<el-col :span="18">
									<div>{{list.alarmTime ? list.alarmTime : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="6">
									<div class="field-title">行车车速:</div>
								</el-col>
								<el-col :span="18">
									<div>{{list.speedDesc ? list.speedDesc : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="6">
									<div class="field-title">前车车速:</div>
								</el-col>
								<el-col :span="18">
									<div>{{list.frontSpeed ? list.frontSpeed : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="7">
									<div class="field-title">前车/行人距离:</div>
								</el-col>
								<el-col :span="17">
									<div>{{list.frontDistance ? list.frontDistance : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="6">
									<div class="field-title">偏离类型:</div>
								</el-col>
								<el-col :span="18">
									<div>{{list.deviateType ? list.deviateType : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="9">
									<div class="field-title">道路标志识别类型:</div>
								</el-col>
								<el-col :span="15">
									<div>{{list.roadSign ? list.roadSign : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="8">
							<el-row style="padding-top: 10px;">
								<el-col :span="9">
									<div class="field-title">道路标志识别数据:</div>
								</el-col>
								<el-col :span="15">
									<div>{{list.roadSignValue ? list.roadSignValue : '--'}}</div>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
					<el-row :gutter="22" style="padding-top: 10px;font-weight: bold;">
						<el-col style="margin: 10px 0 ">
							<div style="height: 20px;line-height: 20px;border-left: 3px solid #0c8b43;padding-left: 6px;;color:#409EFF"
                            >附件信息</div>
						</el-col>
					</el-row>
					<el-row style="padding-top: 10px;">
						<el-col :span="1">
							<div style="line-height: 20px;"  class="field-title">照片:</div>
						</el-col>
						<el-col :span="10" >
							<el-carousel v-if="Array.isArray(fileList)&&fileList.length>0" height="350px" :interval="5000" arrow="always">
								<el-carousel-item v-for="(item,index) in fileList" :key="index">
									<el-image :src="$fileUrl + item.filePath" :preview-src-list="pictureFiles"/>
								</el-carousel-item>
							</el-carousel>

                            <el-image v-else  style="width: 100%;height: 350px;background-color: rgba(200,200,200,0.2);border-radius: 5px;">
                                <div slot="error" class="image-slot">
                                    <div style="height: 350px;display: flex;justify-content: center;align-items: center;flex-direction: column">
                                        <i class="el-icon-picture-outline" style="font-size: 60px;color: #ccc;"></i>
                                        <div style="color: #ccc;">暂无图片</div>
                                    </div>
                                </div>
                            </el-image>
						</el-col>



                        <el-col :span="2">
                            <div  style="line-height: 20px;position: relative;left: 60px;"  class="field-title">视频:</div>
                        </el-col>
                        <el-col :span="10">
<!--                            <div v-for="(item,index) in videoList" :key="index">-->
<!--                                <div class="videoH" v-if="/\.mp4/.test(item.filePath)" style="padding-bottom: 10px;cursor: pointer;" @click="clickVideo(item)">-->
<!--                                    <video :src="$fileUrl+item.filePath" width="300" height="200"></video>-->
<!--                                </div>-->
<!--                            </div>-->
                            <el-carousel v-if="Array.isArray(videoList)&&videoList.length>0" height="350px" :interval="5000" arrow="always">
                                <el-carousel-item v-for="(item,index) in videoList" :key="index">
<!--                                    <img :src="$fileUrl + item.filePath" @click="clickImg(item.filePath)">-->
                                    <div class="videoH" style="padding-bottom: 10px;cursor: pointer;" @click="clickVideo(item)">
                                        <video :src="$fileUrl+item.filePath" width="100%" height="350"></video>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>

                            <el-image v-else  style="margin-left:15px;width: 100%;height: 350px;background-color: rgba(200,200,200,0.2);border-radius: 5px;">
                                <div slot="error" class="image-slot">
                                    <div style="height: 350px;display: flex;justify-content: center;align-items: center;flex-direction: column">
                                        <i class="el-icon-video-camera" style="font-size: 60px;color: #ccc;"></i>
                                        <div style="color: #ccc;">暂无视频</div>
                                    </div>
                                </div>
                            </el-image>
                        </el-col>
					</el-row>
<!--					<el-row style="padding-top: 10px;">-->
<!--						-->
<!--					</el-row>-->
					<div class="videoBox" v-if="dialogVisible">
						<div style="padding: 20px;background-color: #FFFFFF;margin: 10vh auto 0;width: 50%;">
							<el-row>
							  <el-col :span="23"><div style="width: 1px;height: 1px;"></div></el-col>
							  <el-col :span="1"><i @click="closeVideo" class="el-icon-error" style="font-size: 25px;padding-bottom: 10px;"></i></el-col>
							</el-row>
							<video style="display: block;width: 100%;" autoplay="autoplay" ref="videoPlay" :src="videoUrl" controls="controls"></video>
						</div>
					</div>
					<div class="videoBox" v-if="dialogImg">
						<div style="padding: 20px;background-color: #FFFFFF;margin: 10vh auto 0;width: 40%;">
							<el-row>
							  <el-col :span="23"><div style="width: 1px;height: 1px;"></div></el-col>
							  <el-col :span="1"><i @click="closeImgs" class="el-icon-error" style="font-size: 25px;padding-bottom: 10px;"></i></el-col>
							</el-row>
							<el-image :src="imgUrl" />
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</el-dialog>
</template>

<script>
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				list: {},
				fileList: [],
				videoList: [],
				sysUserVo: {},
				videoUrl: '',
				imgUrl: '',
				dialogVisible: false,
				dialogImg: false,

                pictureFiles:[]
			}
		},
		created() {},
		mounted() {
			this.drivingBehaviorAlarm()
		},
		methods: {
			drivingBehaviorAlarm() {
				this.$axiosReq('/adapter/server/escort/web/drivingBehaviorAlarm/' + this.row.id, null, null, 'get').then(res => {
					this.list = res.data;
					if (res.data.sysUserVo) {
						this.sysUserVo = res.data.sysUserVo;
					}
					if (res.data.pictureFiles && res.data.pictureFiles.length > 0) {
						this.fileList = res.data.pictureFiles;

                        this.pictureFiles = res.data.pictureFiles.map(item=> {
                            return this.$fileUrl + item.filePath
                        });

					}
					if (res.data.videoFiles && res.data.videoFiles.length > 0) {
						this.videoList = res.data.videoFiles.filter(item=>/\.mp4/.test(item.filePath)===true);
					}
				})
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			},
			clickVideo(val) {
				let vd = val.fileName.split('.')[1];
				if (vd == 'mp4') {
					this.videoUrl = this.$fileUrl + val.filePath;
					this.dialogVisible = true;
				}
			},
			clickImg(val) {
				this.dialogImg = true;
				this.imgUrl = this.$fileUrl + val;
			},
			closeVideo() {
				let videoPlay = this.$refs.videoPlay;
				videoPlay.pause();
				this.videoUrl = '';
				this.dialogVisible = false;
			},
			closeImgs() {
				this.dialogImg = false;
			}
		}
	}
</script>

<style scoped>
	.headerImg {
		width: 90%;
		height: 300px;
		margin: 0 auto;
		border: 1px solid #d3d3d3;
	}

    .field-title {
        font-weight: bold;
    }

	.headerImg img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.videoBox {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
	}

	.videoH:hover{
		color: #007AFF;
	}
</style>
